import React from 'react';
import FooterInterface from './FooterInterface';

class Footer extends React.Component<FooterInterface.IProps, FooterInterface.IState> {
    render(): JSX.Element {
        return (
            <ul className="text-center">
                <li className="inline-block">
                    <a
                        className="px-2 text-sm text-white opacity-80 transition duration-500 ease-in-out"
                        href="mailto:info@colortheworld.eu"
                    >
                        Kontakt
                    </a>
                </li>
                <li className="inline-block">
                    <a
                        className="px-2 text-sm text-white opacity-80 transition duration-500 ease-in-out"
                        href="/data/impressum"
                    >
                        Impressum
                    </a>
                </li>
                <li className="inline-block">
                    <a
                        className="px-2 text-sm text-white opacity-80 transition duration-500 ease-in-out"
                        href="/data/datenschutz"
                    >
                        Datenschutz
                    </a>
                </li>
            </ul>
        );
    }
}

export default Footer;
