import React from 'react';
import { Link } from 'react-router-dom';
import PointOfInterestInterface from './PointOfInterestInterface';

class PointOfInterest extends React.Component<PointOfInterestInterface.IProps, PointOfInterestInterface.IState> {
    navigateToPointOfInterest = (poiId: number): void => {
        window.location.href = '/detail/' + poiId;
    };

    render(): JSX.Element {
        const { pointOfInterest, language } = this.props;

        return (
            <>
                <Link to={'/' + pointOfInterest.name}>
                    <div
                        className="md:flex bg-white text-white bg-opacity-10 md:p-0 my-4 shadow-lg bg-cover"
                        style={{ backgroundImage: 'url(' + pointOfInterest.image.url + ')' }}
                    >
                        <div className="gradientBackground">
                            <div className="pt-3 text-center md:text-left pb-8 pt-28">
                                <blockquote className="pb-2 px-6">
                                    <h2 className="text-xl uppercase">
                                        {language === 'de' ? pointOfInterest.title : pointOfInterest.enTitle}
                                    </h2>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </Link>
            </>
        );
    }
}

export default PointOfInterest;
