import React from 'react';
import ColorControlPanelInterface from './ColorControlPanelInterface';
import ControlColorApi from '../../Api/ControlColor/ControlColorApi';
import ControlColorModelInterface from '../../Api/ControlColor/Models/ControlColorModelInterface';
import Close from '../../../close.svg';

class ColorControlPanel extends React.Component<ColorControlPanelInterface.IProps, ColorControlPanelInterface.IState> {
    protected colorsApi: ControlColorApi;

    protected intervalId;

    constructor(props: ColorControlPanelInterface.IProps) {
        super(props);
        this.colorsApi = new ControlColorApi();
        this.state = {
            open: false,
            sending: false,
            colors: [],
            countDown: 0,
        };
    }

    async componentDidMount(): Promise<void> {
        const colors = await this.colorsApi.get();
        this.setState({ colors });
    }

    componentWillUnmount(): void {
        clearInterval(this.intervalId);
    }

    sendControlColor = async (color: ControlColorModelInterface): Promise<void> => {
        this.setState({ sending: true });
        const { objectId } = this.props;
        const postData = Object.assign({ point_of_intereset: objectId }, color);
        this.toggleControlPanel();
        try {
            const result = await this.colorsApi.set(postData);

            this.setState({ sending: false, countDown: (result - 1) * 10 });
            this.intervalId = setInterval(() => {
                this.updateCountDown();
            }, 1000);
        } catch (e) {
            console.log(e);
        }
    };

    toggleControlPanel = (): void => {
        const { open } = this.state;

        this.setState({ open: !open });
    };

    updateCountDown = (): void => {
        const { countDown } = this.state;
        if (countDown === 0) return clearInterval(this.intervalId);

        this.setState({
            countDown: countDown - 1,
        });
    };

    render(): JSX.Element {
        const { open, colors, countDown } = this.state;

        const date = new Date(0);
        date.setSeconds(countDown); // specify value for SECONDS here
        const timeString = date.toISOString().substr(11, 8);
        const disabled = countDown > 0 ? true : false;

        return (
            <div className="pb-4 px-4">
                <button
                    disabled={disabled}
                    onClick={() => this.toggleControlPanel()}
                    className="mt-4 w-full py-3 border rounded headline uppercase bg-black text-white"
                >
                    {countDown > 0 ? <>{timeString}</> : 'Color Your World'}
                </button>
                {open && (
                    <div className="fixed left-0 bottom-0 w-full h-screen bg-black bg-opacity-60 text-center -mb-10 z-50">
                        <div className="h-auto w-full bg-gray-800 pb-6 fixed bottom-0 pt-10">
                            <button
                                onClick={() => this.toggleControlPanel()}
                                className="inline-block w-16 h-16 -mt-16 absolute -ml-8 rounded-full bg-gray-800 mx-auto text-center"
                            >
                                <img className="inline-block w-6 h-6" src={Close} alt="close icon" />
                            </button>
                            <div className="grid grid-flow-col grid-cols-4 grid-rows-4 gap-auto mx-4">
                                {colors.map((color) => {
                                    return (
                                        <div key={color.color}>
                                            <span
                                                onClick={() => this.sendControlColor(color)}
                                                className="inline-block h-10 w-10 m-2 rounded-full shadow"
                                                style={{ backgroundColor: '#' + color.color }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default ColorControlPanel;
