import React from 'react';
import Footer from '../Base/Footer/Footer';
import Header from '../Base/Header/Header';
import ColorControlPanel from '../Base/ColorControlPanel/ColorControlPanel';
import LayoutInterface from './LayoutInterface';

class LayoutDetail extends React.Component<LayoutInterface.IProps, LayoutInterface.IState> {
    render(): JSX.Element {
        const { language, switchLanguage } = this.props;
        const { children, detailData } = this.props;

        return (
            <div className="h-screen">
                <Header language={language} switchLanguage={switchLanguage} />
                <main className="h-screen overscroll-auto">{children}</main>
                <div className="gradientBackgroundFooter fixed bottom-0 left-0 right-0 pb-6 pt-2">
                    <ColorControlPanel objectId={detailData.id} />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default LayoutDetail;
