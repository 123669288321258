import React from 'react';
import { Route } from 'react-router-dom';
import back from './back.svg';
import BackButtonInterface from './BackButtonInterface';

class BackButton extends React.Component<BackButtonInterface.IProps, BackButtonInterface.IState> {
    handleBackClick = (path: string): void => {
        if (path.includes('list')) {
            window.location.href = '/';
        }

        if (path.includes('detail')) {
            window.location.href = '/';
        }
    };

    render(): JSX.Element {
        return (
            <>
                <Route
                    render={({ history }) => (
                        <button
                            onClick={() => {
                                history.push('/');
                            }}
                        >
                            <img className="w-12" src={back} alt="dlfjsd" />
                        </button>
                    )}
                />
            </>
        );

        return <></>;
    }
}

export default BackButton;
