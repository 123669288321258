import React from 'react';
import NavigationBarInterface from './NavigationBarInterface';
import BackButton from './Components/BackButton/BackButton';
import MenuButton from './Components/MenuButton/MenuButton';

class NavigationBar extends React.Component<NavigationBarInterface.IProps, NavigationBarInterface.IState> {
    render(): JSX.Element {
        const { detailData } = this.props;

        return (
            <nav className="flex absolute left-0 right-0 z-50 px-6 py-0 -mt-24">
                <div className="flex-none w-8 h-8 text-center">
                    <BackButton {...this.props} />
                </div>
                <div className="flex-grow h-8 text-center" />
                <div className="flex-none w-8 h-8 text-center">{detailData.webCam && <MenuButton />}</div>
            </nav>
        );
    }
}

export default NavigationBar;
