import React from 'react';
import DetailPageInterface from './DetailPageInterface';
import PointOfInterestApi from '../../Api/PointOfInterest/PointOfInterestApi';
import NavigationBar from '../../Base/NavigationBar/NavigationBar';
import LayoutDetail from '../../Layout/LayoutDetail';

class DetailPage extends React.Component<DetailPageInterface.IProps, DetailPageInterface.IState> {
    protected api: PointOfInterestApi;

    constructor(props: DetailPageInterface.IProps) {
        super(props);
        this.api = new PointOfInterestApi();
        this.state = {
            detailData: null,
            images: [],
        };
    }

    async componentDidMount(): Promise<void> {
        const { location } = this.props;
        const detailData = await this.api.getBySlug(location.pathname.replace('/', ''));

        this.setState({ detailData });
    }

    render(): JSX.Element {
        const { language, switchLanguage } = this.props;
        const { detailData } = this.state;

        if (!detailData) return <></>;

        const twitchIframeUrl =
            'https://player.twitch.tv/?channel=' +
            detailData.twitchChannelId +
            '&parent=' +
            process.env.REACT_APP_APP_URL +
            '&muted=true';

        return (
            <LayoutDetail language={language} switchLanguage={switchLanguage} detailData={detailData}>
                <div className="pb-10 pt-28">
                    <NavigationBar {...this.props} detailData={detailData} />
                    <div
                        className="bg-cover bg-center h-screen w-screen top-0 left-0 fixed"
                        style={{ backgroundImage: 'url(' + detailData.image.url + ')' }}
                    >
                        {detailData.video && !detailData.twitchChannelId && (
                            <div style={{ position: 'fixed', zIndex: -99, width: '100%', height: '100%' }}>
                                <iframe
                                    frameBorder="0"
                                    height="100%"
                                    width="100%"
                                    src={detailData.video}
                                    allowFullScreen
                                />
                            </div>
                        )}
                        {detailData.twitchChannelId && !detailData.video && (
                            <div style={{ position: 'fixed', zIndex: -99, width: '100%', height: '100%' }}>
                                <iframe
                                    frameBorder="0"
                                    src={twitchIframeUrl}
                                    height="100%"
                                    width="100%"
                                    allowFullScreen
                                />
                            </div>
                        )}
                        <div className="gradientBackground text-white max-h-full overflow-y-auto pb-4 pt-32 px-4">
                            <h2 className="headline uppercase text-center text-3xl mt-0">
                                {language === 'de' ? detailData.title : detailData.enTitle}
                            </h2>
                            <div className="text-center text-lg font-thin my-3 description max-h-screen pb-28">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: language === 'de' ? detailData.description : detailData.enDescription,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutDetail>
        );
    }
}

export default DetailPage;
