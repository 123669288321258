import Api from '../Api';
import ControlColorModelInterface from './Models/ControlColorModelInterface';
import PostDataInterface from './Models/PostDataInterface';

class ControlColorApi extends Api {
    get = async (): Promise<ControlColorModelInterface[]> => {
        const response = await fetch(this.apiUrl + '/colors', this.options);

        if (response.ok) {
            return (await response.json()) as ControlColorModelInterface[];
        }

        return Promise.reject(response);
    };

    set = async (postData: PostDataInterface): Promise<number> => {
        const options = Object.assign(this.options, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: this.options.headers,
        });
        const response = await fetch(this.apiUrl + '/control-signal', options);

        if (response.ok) {
            return (await response.json()) as number;
        }

        return Promise.reject(response);
    };
}

export default ControlColorApi;
