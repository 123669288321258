import Api from '../Api';
import PointOfInterestInterface from './Models/PointOfInterestInterface';

class PointOfInterestApi extends Api {
    get = async (): Promise<PointOfInterestInterface.PointOfInterestModelInterface[]> => {
        const response = await fetch(this.apiUrl + '/points-of-interest', this.options);

        if (response.ok) {
            return (await response.json()) as PointOfInterestInterface.PointOfInterestModelInterface[];
        }

        return Promise.reject(response);
    };

    getBySlug = async (slug: string): Promise<PointOfInterestInterface.PointOfInterestModelInterface> => {
        const response = await fetch(this.apiUrl + '/points-of-interest/' + slug, this.options);

        if (response.ok) {
            return (await response.json()) as PointOfInterestInterface.PointOfInterestModelInterface;
        }

        return Promise.reject(response);
    };
}

export default PointOfInterestApi;
