import ApiHeadersInterface from './PointOfInterest/ApiHeadersInterface';

class Api {
    protected options: ApiHeadersInterface.HeadersInterface;

    protected apiUrl: string;

    constructor() {
        const myHeaders = new Headers();
        myHeaders.append('X-API-KEY', '1234567890');

        this.apiUrl = process.env.REACT_APP_API_URL || 'https://api-colortheworld.eu/api';
        this.options = {
            method: 'GET',
            headers: myHeaders,
        };
    }
}

export default Api;
