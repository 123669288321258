import React from 'react';
import menuIcon from './menu.svg';
import MenuButtonInterface from './MenuButtonInterface';

class MenuButton extends React.Component<MenuButtonInterface.IProps, MenuButtonInterface.IState> {
    constructor(props: MenuButtonInterface.IProps) {
        super(props);
        this.state = {
            open: false,
        };
    }
    toggleMenu = (): void => {
        const { open } = this.state;

        this.setState({
            open: !open,
        });
    };

    render(): JSX.Element {
        return (
            <div className="relative">
                <button onClick={() => this.toggleMenu()} className="w-8 h-8 rounded-full focus:outline-none">
                    <img className="w-8" src={menuIcon} alt="alt text" />
                </button>
            </div>
        );
    }
}
export default MenuButton;
