import React from 'react';
import PointOfInterestListInterface from './PointOfInterestListInterface';
import PointOfInterest from '../PointOfInterest/PointOfInterest';

class PointOfInterestList extends React.Component<
    PointOfInterestListInterface.IProps,
    PointOfInterestListInterface.IState
> {
    render(): JSX.Element {
        const { count, pointsOfInterest, language } = this.props;

        if (!pointsOfInterest || pointsOfInterest.length === 0) return <></>;

        return (
            <ul className="px-6">
                {pointsOfInterest.map((pointOfInterest, index) => {
                    if (index >= count) return null;
                    return (
                        <li key={pointOfInterest.id}>
                            <PointOfInterest pointOfInterest={pointOfInterest} language={language} />
                        </li>
                    );
                })}
            </ul>
        );
    }
}

export default PointOfInterestList;
