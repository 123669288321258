import React from 'react';
import ErrorPageInterface from './ErrorPageInterface';

class ErrorPage extends React.Component<ErrorPageInterface.IProps, ErrorPageInterface.IState> {
    render(): JSX.Element {
        return <>ErrorPage</>;
    }
}

export default ErrorPage;
