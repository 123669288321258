import React from 'react';
import Logo from '../../../logo-black.svg';
import HeaderInterface from './HeaderInterface';

class Header extends React.Component<HeaderInterface.IProps, HeaderInterface.IState> {
    render(): JSX.Element {
        const { language, switchLanguage } = this.props;

        return (
            <div className="gradientBackgroundHeader fixed top-0 left-0 right-0 h-28 py-2 z-50">
                <div className="grid grid-cols-3">
                    <div />
                    <div className="text-center pt-8">
                        <div className="inline-block rounded-md border ">
                            <button
                                className={`py-1 px-2 rounded-l ${
                                    language === 'de' ? 'bg-white text-black' : 'text-white'
                                }`}
                                onClick={() => switchLanguage()}
                            >
                                DE
                            </button>
                            <button
                                className={`py-1 px-2 rounded-r ${
                                    language === 'en' ? 'bg-white text-black' : 'text-white'
                                }`}
                                onClick={() => switchLanguage()}
                            >
                                EN
                            </button>
                        </div>
                    </div>
                    <div>
                        <img className="absolute top-3 right-3 w-auto h-24" src={Logo} alt="Logo" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
