import React from 'react';
import HomePageInterface from './HomePageInterface';
import PointOfInterestApi from '../../Api/PointOfInterest/PointOfInterestApi';
import PointOfInterestList from '../../Base/PointOfInterestList/PointOfInterestList';
import Layout from '../../Layout/Layout';

class HomePage extends React.Component<HomePageInterface.IProps, HomePageInterface.IState> {
    protected poiApi: PointOfInterestApi;

    constructor(props: HomePageInterface.IProps) {
        super(props);
        this.state = {
            pointsOfInterest: [],
        };
        this.poiApi = new PointOfInterestApi();
    }

    async componentDidMount(): Promise<void> {
        const pointsOfInterest = await this.poiApi.get();

        this.setState({
            pointsOfInterest,
        });
    }

    render(): JSX.Element {
        const { language, switchLanguage } = this.props;
        const { pointsOfInterest } = this.state;

        return (
            <Layout language={language} switchLanguage={switchLanguage}>
                <div className="py-3 max-h-full overflow-y-auto pb-10 pt-28">
                    <PointOfInterestList pointsOfInterest={pointsOfInterest} language={language} count={20} />
                </div>
            </Layout>
        );
    }
}

export default HomePage;
