import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PageTransition } from '@steveeeie/react-page-transition';
import AppInterface from './AppInterface';
import DetailPage from './components/Pages/DetailPage/DetailPage';
import ErrorPage from './components/Pages/ErrorPage/ErrorPage';
import HomePage from './components/Pages/HomePage/HomePage';

class App extends React.Component<AppInterface.IProps, AppInterface.IState> {
    constructor(props: AppInterface.IProps) {
        super(props);
        this.state = {
            language: 'de',
        };
    }

    componentDidMount(): void {
        this.setState({
            language: window.location.hash && window.location.hash === 'en' ? 'en' : 'de',
        });
    }

    switchLanguage = (): void => {
        const { language } = this.state;

        this.setState({
            language: language === 'de' ? 'en' : 'de',
        });
    };

    render(): JSX.Element {
        const { language } = this.state;

        return (
            <div className="bg-gray-500 h-screen min-h-screen bg-01">
                <main className="h-screen min-h-screen">
                    <BrowserRouter>
                        <Route
                            render={({ location }) => {
                                const preset = location.pathname === '/' ? 'fadeFromBottom' : 'fadeFromTop';
                                return (
                                    <PageTransition preset={preset} transitionKey={location.pathname}>
                                        <Switch location={location}>
                                            <Route path="/" exact>
                                                <HomePage
                                                    location={location}
                                                    language={language}
                                                    switchLanguage={this.switchLanguage}
                                                />
                                            </Route>
                                            <Route path="/:slug">
                                                <DetailPage
                                                    location={location}
                                                    language={language}
                                                    switchLanguage={this.switchLanguage}
                                                />
                                            </Route>
                                            <Route component={ErrorPage} />
                                        </Switch>
                                    </PageTransition>
                                );
                            }}
                        />
                    </BrowserRouter>
                </main>
            </div>
        );
    }
}

export default App;
